@import "fonts";


.main-title-simple {
  color: var(--primary-text-color);
  font-size: 22px;
}

.main-title {
  @extend .main-title-simple;
  font-weight: 700;
}

.main-title-with-buffers {
  @extend .main-title;
  margin: 8px 0 12px 0;
  text-align: center;
}

.all-keys {
  display: flex;
}

.key-container {
  display: flex;
  flex-direction: column;
  margin: 8px;
}

.sub-title {
  @extend .bio-sans-font;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 0.28px;
  color: var(--primary-text-color);
  opacity: 1;
}

.sub-title-with-buffers {
  @extend .sub-title;
  margin: 8px 0 12px 0;
}

.description {
  font-size: 18px;
  color: #747979;
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: var(--primary-text-color);
}
