
$font-path: '/assets/fonts/';

@font-face {
  font-family: 'HurmeGeometricSans3-Regular';
  src: url($font-path + 'HurmeGeometricSans3/HurmeGeometricSans3-Regular.otf');
}

@font-face {
  font-family: 'HurmeGeometricSans3-SemiBold';
  src: url($font-path + 'HurmeGeometricSans3/HurmeGeometricSans3-SemiBold.otf');
}

@font-face {
  font-family: 'HurmeGeometricSans3-Black';
  src: url($font-path + 'HurmeGeometricSans3/HurmeGeometricSans3-Black.otf');
}


@font-face {
  font-family: 'icomoon';
  src: url($font-path + 'icomoon.eot?i6p4tv');
  src: url($font-path + 'icomoon.eot?i6p4tv#iefix') format('embedded-opentype'), url($font-path + 'icomoon.ttf?i6p4tv') format('truetype'),
  url($font-path + 'icomoon.woff?i6p4tv') format('woff'), url($font-path + 'comoon.svg?i6p4tv#icomoon') format('svg');
}

@font-face {
  font-family: 'bio-sans';
  src: url($font-path + 'bio-sans/BioSansRegular.otf');
}

@font-face {
  font-family: 'Poppins';
  src: url($font-path + 'poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url($font-path + 'poppins/Poppins-Bold.ttf');
}

.bio-sans-font {
  font-family: 'bio-sans';
}

.poppins-font {
  font-family: 'Poppins';
}

.poppins-bold-font {
  font-family: 'Poppins-Bold';
}

.azo-sans-font {
  //font-family: Azo Sans;
  font-family: azo-sans-web, sans-serif;
}

.roboto-font {
  font-family: Roboto;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v103/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}
/* fallback */
@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v79/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  color: var(--primary-icon-color);
  opacity: 0.3;
}
