@import "fonts";

.simple-btn {
  min-width: 20px !important;;
  height: 20px !important;
  line-height: 13px !important;
  min-height: 20px !important;
  vertical-align: top !important;
  font-size: 10px;
  padding: 0 5px 0 5px !important;
  margin: 0 2px 0 2px;
}

.simple-toggle-btn-group {
}

.main-btn-without-border {
  background: var(--button-background-color) !important;
  font: normal normal 600 13px/20px Poppins;
  letter-spacing: 0.33px;
  color: var(--button-text-color);
  border-radius: 5px !important;
}

.main-btn {
  @extend .main-btn-without-border;
  border-radius: 6px !important;
  box-shadow: 0px 1px 3px #00000029;
  color: var(--button-text-color)
}
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.main-btn:not(.mat-button-disabled) {
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.simple-toggle-btn-group > mat-button-toggle > button > span {
  min-width: 24px !important;;
  height: 24px !important;
  line-height: 24px !important;
  padding: 5px 8px !important;
  text-align: center;
}

.gradiant-btn {
  @extend .poppins-font;
  letter-spacing: 0.33px !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  transition: background-color 0.5s, color 0.5s, box-shadow 0.5s;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.gradiant-btn:not(.mat-button-disabled) {
  background: transparent linear-gradient(129deg, #C530D1 -70%, #2169BE 100%);
  color: #FFFFFF !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.gradiant-btn.mat-button-disabled {
  background: transparent linear-gradient(129deg, rgba(197,48,209,0.3) -70%, rgba(33, 105, 190, 0.3) 100%);
}


.gradiant-btn:hover {
  box-shadow: 0 3px 10px #000000;
}

.close-btn {
  @extend .bio-sans-font;
  font-size: 15px !important;
  letter-spacing: 0.38px !important;
  text-decoration: underline !important;
  color: var(--secondary-text-color) !important;
}

.blue-btn {
  @extend .poppins-font;
  padding: 7px 14px;
  background-color: var(--bluish-background-color);
  color: #FFFFFF;
  letter-spacing: 0.33px;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
}

.blue-btn:hover {
  color: #FFFFFF;
}

img {
  display: inline;
}
