
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #eeeeee;
}

::-webkit-scrollbar-thumb {
  min-height: 20px;
  border: 0px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.05), inset 1px 1px 0 rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: #eeeeee;
}
