
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: var(--secondary-text-color) !important;
}

.svg-images-filter{
  filter: contrast(var(--svg-contranst-value));
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-slide-toggle-content{
  color: var(--primary-text-color) !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface{
  padding:25px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-toggle{
  background-color: var(--secondary-background-color);
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-toggle-button{
  color: var(--primary-text-color) !important;
}

.mat-datepicker-actions{
  background-color: var(--primary-background-color) !important;
}

.mat-mdc-raised-button{
  background-color: var(--button-background-color) !important;
  color: var(--button-text-color) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-mdc-raised-button.mat-button-disabled{
  background-color: var(--secondary-background-color) !important;
  color: var(--secondary-text-color) !important;
}

.mat-mdc-button{
  color: var(--button-text-color) !important;
}

.mat-datepicker-content-container{
  background-color: var(--primary-background-color) !important;
}

.mat-mdc-paginator-range-label, .mat-mdc-paginator-page-size{
  color: var(--primary-text-color) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-label{
  color: var(--primary-text-color) !important;
}

.mat-mdc-icon-button{
  color: var(--primary-icon-color) !important;
}


/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-mdc-icon-button.mat-button-disabled{
  color: var(--secondary-background-color) !important;
}

.mat-mdc-paginator-container{
  background-color: var(--primary-background-color) !important;
}

.mat-mdc-dialog-surface{
  background-color: var(--primary-background-color) !important;
}


/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-value{
  color: var(--primary-text-color);
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-type-mat-select .mat-form-field-label, .mat-select-value {
  color: var(--primary-text-color);
}


/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-frame {
  border-color: var(--primary-icon-color) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: var(--secondary-background-color) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
.mat-option-text{
  color: var(--primary-text-color) !important;
}

.open-numeric-answer-input{
  color: var(--primary-text-color);
}

.mat-mdc-option{
  background-color: var(--secondary-background-color) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-label{
  color: var(--primary-text-color) !important;
}


.mat-primary .mat-mdc-option.mat-mdc-selected{
  background-color: var(--primary-background-color);
  color: var(--primary-text-color);
}

.mat-mdc-row{
  height: auto !important;
  color: inherit !important;
}

.mat-mdc-select-panel{
  background-color: transparent;
  color: var(--primary-text-color);
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-disabled .mat-select-value{
  color: var(--secondary-text-color);
}

.mat-calendar {
  background: var(--secondary-background-color);
  border-radius: 5px;
  color: var(--primary-text-color) !important;
}


.mat-calendar-previous-button{
  color: var(--primary-icon-color) !important;
}

.mat-form-field-invalid{
  color: var(--danger-background-color) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-invalid .mat-form-field-outline {
  color: red !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-outline {
  color: var(--border-color) !important;
}

.mat-datepicker-toggle-default-icon{
  color: var(--primary-icon-color) !important;
}


.mat-calendar-next-button{
  color: var(--primary-icon-color) !important;
}

.mat-calendar-body-cell-content{
  color: var(--primary-text-color) !important;
}

.mat-date-range-input {
  color: var(--primary-text-color);
}
.mat-date-range-input-separator {
  color: var(--primary-text-color);
}

.mat-mdc-form-field-hint{
  color: var(--secondary-text-color);
}

.mat-mdc-input-element{
  color: var(--primary-text-color);
}

.cdk-text-field-autofill{
  color: var(--primary-text-color);
}

.mat-mdc-form-field {
  .mat-mdc-input-element {
    color: var(--primary-text-color);
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-label {
    color: var(--primary-icon-color);
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-underline {
    background-color: var(--border-color);
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-ripple {
    background-color: var(--border-color);
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-required-marker {
    color: slategray;
  }
}

::placeholder{
  color: var(--secondary-text-color) !important;
}

.mat-mdc-dialog-container{
  background-color: var(--dialog-background-color) !important;
}

.mat-mdc-dialog-content{
  color : var(--primary-text-color) !important;
}

.cal-week-view{
  background-color: var(--secondary-background-color);
}
.cal-hour-segment{
  background-color: var(--primary-background-color) !important;
  color: var(--primary-text-color) !important;
}

.cal-hour-segment:hover{
  background-color: #e1e1e1 !important;
}

.cal-today{
  background-color: var(--success-text-color) !important;
}

.cal-day-headers{
  background-color: var(--primary-background-color);
  color: var(--primary-text-color) !important;
}

.cal-weekend span{
  color: var(--primary-text-color) !important;
}


.cal-header:hover{
  background-color: var(--secondary-background-color) !important;
}

.mdc-text-field--filled {
  background-color: var(--neutral-07) !important;
}

.mdc-text-field--filled .mdc-text-field__input, .mdc-text-field--filled .mdc-floating-label,
.mat-mdc-select-value-text, .mat-mdc-option .mdc-list-item__primary-text, .mat-mdc-checkbox label,
.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text, .mat-mdc-slide-toggle .mdc-switch:enabled+.mdc-label,
.mat-mdc-outlined-button:not(:disabled), .mat-mdc-chip.mdc-evolution-chip--with-trailing-action .mat-mdc-chip-action-label,
.mat-mdc-tab .mdc-tab__text-label
{
  color: var(--primary-text-color) !important;
}

.project-select-container .mat-mdc-form-field-subscript-wrapper{
  display: none;
}

.mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input, .mat-mdc-select-arrow,
.mat-mdc-menu-item .mat-mdc-menu-submenu-icon, .mat-mdc-chip-remove .mat-icon{
  color: var(--text-field-disabled-input-text-color) !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background,
.mat-mdc-option .mat-pseudo-checkbox-full{
  border-color: var(--text-field-disabled-input-text-color) !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface{
  background-color: var(--neutral-06) !important;
}


input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}

.mat-mdc-table-sticky {
}
