@import "fonts";


.main-component {
  box-shadow: 0 3px 10px #0000000F;
  border-radius: 5px;
  background: var(--secondary-background-color);
  color: var(--primary-text-color);
  padding: 20px 30px 40px 30px;
}


.external-main-component {
  @extend .main-component;
  box-shadow: 0 3px 10px #0000003F;
}

.clickable-main-component {
  @extend .main-component;
  cursor: pointer;
  transition: box-shadow 0.25s, border 0.25s;
  border: 1px solid rgba(1,1,1, 0);
}

.clickable-main-component:hover {
  box-shadow: 0 3px 8px 3px rgb(200 200 200 / 40%);
  border: 1px solid #d3d3d3;
}

.actions-container {
  display: flex;
  flex-wrap: wrap;

  button, a {
    margin-bottom: 8px;
    margin-right: 8px;
  }
}

.actions-container-with-buffer {
  @extend .actions-container;
  margin: 8px 0;
}

.scroll-table-container {
  overflow-x: auto;
}

.page-container {
  @extend .external-main-component;
  background-color: var(--neutral-08) !important;
  display: flex;
  flex-direction: column;
  color: var(--primary-text-color);
  box-shadow: none;
}

.page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 100%;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-flex {
  padding-top: 0.85em !important;
}

.flex-spacer {
  flex: 1;
}


.dialog-title-container {
  display: flex !important;
  justify-content: space-between;
  color: var(--primary-icon-color);

  .dialog-title {
    @extend .poppins-font;
    font-size: 17px;
    line-height: 29px;
    font-weight: 700;
    letter-spacing: 0;
    color: var(--primary-text-color);
    opacity: 1;
  }

  mat-icon {
    cursor: pointer;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
::ng-deep .mat-form-field-appearance-outline .mat-form-field-infix {
  border-top: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
::ng-deep .mat-form-field-appearance-outline .mat-form-field-prefix {
  margin-right: 4px;
  transform: translateY(-4px);
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
::ng-deep .mat-form-field-disabled .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38) !important;
}

.beta {
  @extend .bio-sans-font;
  border-radius: 20px;
  color: whitesmoke;
  background-color: rgba(233, 0, 96, 0.72);
  padding: 5px 20px;
}

.my-tooltip {
  white-space: pre-line;
}
