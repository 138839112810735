:root
{
  --white-transparent: rgba(256, 256, 256, 0.8);
  --disabled-transparent: rgba(221,221,221, 0.8);
}

$main-color1: #9DB4C0;
$dark-color1: #253237;
$dark-color2: #5C6B73;
$light-color1: #C2DFE3;
$light-color2: #E0FBFC;
$light-color3: #b6d1e0;

